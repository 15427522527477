<template>
  <div class="container">
    <div class="header">
      <div class="header-box">
        <img src="../assets/images/header-icon.png" class="header-icon">
        <span>净化后勤助手</span>
      </div>
    </div>

    <div class="container-one">
      <div class="one-top">
        <img src="../assets/images/one-img.png" class="one-img">
        <div class="one-top-right">
          <div class="one-top-right-box">
            <img src="../assets/images/logo-icon.png" class="logo-icon">
            <span>净化后勤助手</span>
          </div>
          <div class="one-btn">微信小程序</div>
        </div>
      </div>
      <div class="one-bottom">
        <p>净化后勤助手</p>
        <div>净化后勤助手是一款专为净化公司员工设计的工单管理软件APP，可以帮助员工高效地完成各类通用系统、医疗专项系统、净化专项系统、制冷专项系统的设计及安装项目的巡检、保养、报修维修等工作。提升客户满意度，是您身边的贴心助手。</div>
      </div>
    </div>

    <div class="container-two">
      <div class="two-left">
        <p>设备信息，一扫即达</p>
        <div>通过扫描设备二维码，实时查看设备房、手术室等设备与地点的状态、参数、故障信息等</div>
      </div>
      <div class="two-right">
        <img src="../assets/images/tow-1.png">
        <img src="../assets/images/two-2.png" class="two-img">
      </div>
    </div>

    <div class="container-three">
      <div class="three-left">
        <img src="../assets/images/three-1.png">
        <img src="../assets/images/three-2.png">
        <img src="../assets/images/three-3.png">
      </div>
      <div class="three-right">
        <p>今日事项，使命必达</p>
        <div>工程师快速处理巡检计划、保养记录、报修单等信息，并上传至云端数据库</div>
      </div>
    </div>

    <div class="container-four">
      <div class="four-left">
        <p>工单进度，实时跟踪</p>
        <div>方便地与客户和同事沟通协调，共享项目进度和问题反馈</div>
      </div>
      <div class="four-right">
        <img src="../assets/images/four-1.png">
        <img src="../assets/images/four-2.png">
        <img src="../assets/images/four-3.png">
      </div>
    </div>
    <div class="foot">
      <span>版权所有@玖章智能科技有限公司 粤ICP备2020080166-1号</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
}
</script>
<style lang="scss" scoped>
.container {
  overflow: hidden;
  .header {
    width: 100%;
    height: 64px;
    background: #FFFFFF;
    box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.05);
    border-radius: 0px 0px 0px 0px;
    .header-box {
      height: 64px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 361px;
      .header-icon {
        width: 42px;
        height: 42px;
        margin-right: 16px;
      }
      span {
        font-size: 18px;
        font-family: Noto Sans-Medium, Noto Sans;
        font-weight: 500;
        color: #202020;
      }
    }
  }

  .container-one {
    padding-left: 232px;
    width: 100%;
    background: linear-gradient(90deg, #4289FF 0%, rgba(43,228,206,0.53) 100%);
    .one-top {
      display: flex;
      align-items: center;
      .one-img {
        width: 731px;
        height: 487px;
        margin-right: 119px;
      }
      .one-top-right-box {
        display: flex;
        align-items: center;
        .logo-icon {
          width: 80px;
          height: 80px;
          margin-right: 24px;
        }
        span {
          font-size: 36px;
          font-family: Noto Sans-Display SemiBold, Noto Sans;
          font-weight: 700;
          color: #202020;
        }
      }
      .one-btn {
        margin-top: 106px;
        width: 240px;
        height: 56px;
        line-height: 56px;
        background: #5190F9;
        box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
        border-radius: 8px 8px 8px 8px;
        font-size: 18px;
        font-family: Noto Sans-Medium, Noto Sans;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
    .one-bottom {
      height: 218px;
      margin: 47px 0 0 129px;
      text-align: left;
      p{
        margin-bottom: 25px;
        font-size: 36px;
        font-family: Noto Sans-Medium, Noto Sans;
        font-weight: 500;
        color: #000000;
      }
      div {
        width: 1198px;
        margin-left: 2px;
        height: 99px;
        font-family: Noto Sans-Regular, Noto Sans;
        font-weight: 400;
        color: #000000;
        line-height: 28px;
        letter-spacing: 1px;
      }
    }
  }

  .container-two {
    padding-left: 361px;
    width: 100%;
    height: 728px;
    background: rgba(255,255,255,0);
    border-radius: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
    .two-left {
      text-align: left;
      margin-right: 216px;
      p {
        width: 339px;
        height: 49px;
        font-size: 36px;
        font-family: Noto Sans-Medium, Noto Sans;
        font-weight: 500;
        color: #121F3D;
        line-height: 42px;
        letter-spacing: 1px;
        margin-bottom: 17px;
      }
      div {
        width: 347px;
        height: 99px;
        font-size: 24px;
        font-family: Noto Sans-Regular, Noto Sans;
        font-weight: 400;
        color: rgba(0,0,0,0.6);
        line-height: 28px;
      }
    }
    .two-img {
      position: relative;
      top: -84px;
    }
  }

  .container-three {
    padding-left: 95px;
    width: 100%;
    height: 727px;
    background: rgba(81,146,255,0.61);
    border-radius: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
    .three-right {
      margin-left: 51px;
      text-align: left;
      p {
        width: 339px;
        height: 49px;
        font-size: 36px;
        font-family: Noto Sans-Medium, Noto Sans;
        font-weight: 500;
        color: #121F3D;
        line-height: 42px;
        letter-spacing: 1px;
      }
      div {
        margin-top: 21px;
        width: 364px;
        font-size: 24px;
        font-family: Noto Sans-Regular, Noto Sans;
        font-weight: 400;
        color: rgba(0,0,0,0.6);
      }
    }
  }

  .container-four {
    padding-left: 361px;
    width: 100%;
    height: 726px;
    background: rgba(255,255,255,0);
    display: flex;
    align-items: center;
    .four-left {
      text-align: left;
      p {
        width: 339px;
        height: 49px;
        font-size: 36px;
        font-family: Noto Sans-Medium, Noto Sans;
        font-weight: 500;
        color: #121F3D;
        line-height: 42px;
        letter-spacing: 1px;
      }
      div {
        margin: 17px 81px 0 2px;
        width: 363px;
        height: 66px;
        font-size: 24px;
        font-family: Noto Sans-Regular, Noto Sans;
        font-weight: 400;
        color: rgba(0,0,0,0.6);
        line-height: 28px;
      }
    }
  }

  .foot {
    width: 100%;
    height: 150px;
    background: rgba(36,36,36,0.6);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    span {
      width: 336px;
      height: 16px;
      font-size: 12px;
      font-family: Noto Sans-Light, Noto Sans;
      font-weight: 300;
      color: #A39C9C;
      line-height: 14px;
      margin-bottom: 21px;
    }
  }
}
</style>
